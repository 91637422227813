import { factory as biLoggerFactory } from '@wix/web-bi-logger/dist/src/logger';

function getCookie(name: AnyFixMe) {
  const re = new RegExp(`(?:^|\\W)${name}\s*=([^;]*)`);
  const m = window.document.cookie.match(re);
  return m ? m[1].trim() : m;
}

const getImplicitParamsGetter = ({ editorModel, window }: AnyFixMe) => {
  const permissionInfo = editorModel.permissionsInfo || {};

  const implicitFields = {
    ownerId() {
      return permissionInfo.ownerId || '';
    },
    roles(): string[] {
      const loggedInUserRoles = (permissionInfo.loggedInUserRoles || [])
        .map((loggedInUserRole: AnyFixMe) => loggedInUserRole.role)
        .join();
      this.roles = function () {
        return loggedInUserRoles;
      };
      return this.roles();
    },
    esi() {
      return editorModel.editorSessionId || '';
    },
    did() {
      return window.siteId || '';
    },
    msid() {
      return editorModel.metaSiteId || '';
    },
    ts(now: AnyFixMe) {
      return now - (window.mainLoaded || 0);
    },
    c(now: AnyFixMe) {
      return now;
    },
  };

  return function () {
    const now = Date.now();
    const payload = Object.keys(implicitFields).reduce(
      (res: Record<string, AnyFixMe>, key) => {
        res[key] = implicitFields[key as keyof typeof implicitFields](now);
        return res;
      },
      {},
    );
    return payload;
  };
};

export type BiType = ReturnType<typeof createBi>;

/**
 * @deprecated use `editorLoggers.bi.logger` or `editorLoggers.bi.loggerLegacy`
 */
export function createBi({ window, editorModel, suppressbi }: AnyFixMe) {
  const implicitParams = getImplicitParamsGetter({ window, editorModel });

  const biLogger = biLoggerFactory().setMuted(suppressbi).logger();

  function sendBI(reportDef: AnyFixMe, params: AnyFixMe) {
    const endpoint = reportDef.endpoint || 'editor';
    const logParams = {
      src: reportDef.src || 38,
      evid: reportDef.evid,
      ...params,
      ...implicitParams(),
    };

    void biLogger.log(logParams, { endpoint });
  }

  const bi = {
    event(reportDef: AnyFixMe, params: AnyFixMe) {
      if (typeof reportDef === 'number') {
        reportDef = { evid: reportDef };
      }
      sendBI(reportDef, params);
    },
    error: (function () {
      const errorSeverityMap = {
        recoverable: 10,
        warning: 20,
        error: 30,
        fatal: 40,
      };
      return function (reportDef: AnyFixMe, params: AnyFixMe) {
        params = params || {};
        const rd = {
          evid: 10,
          endpoint: reportDef.endpoint || 'trg',
        };
        const p: any = {
          errn: reportDef.errorName,
          errc: reportDef.errorCode,
          sev:
            errorSeverityMap[
              reportDef.severity as keyof typeof errorSeverityMap
            ] || reportDef.severity,
          cat: 1,
          iss: 1,
        };
        const ut = getCookie('userType');
        if (ut) {
          p.ut = ut;
        }
        Object.keys(params).forEach((param) => {
          p[param] = params[param];
        });
        sendBI(rd, p);
      };
    })(),
    flush: biLogger.flush,
  };
  return bi;
}
