import { createEditorFedopsLogger } from './loggers/fedopsLogger';
import { createBiLogger } from './loggers/biLogger';

import type { EditorModel, BILoggerModel } from 'types/core';
import type { EditorParams } from '@/editorParams';
import type { LoggerOptions } from './types';

export const createEditorLoggers = ({
  editorParams,
  editorModel = {},
  biLoggerModel = {},
  options,
}: {
  editorParams: EditorParams;
  editorModel: Partial<EditorModel>;
  biLoggerModel: Partial<BILoggerModel>;
  options: LoggerOptions;
}) => {
  return {
    bi: createBiLogger({
      editorModel: editorModel as EditorModel,
      editorParams,
      options,
    }),
    fedops: createEditorFedopsLogger({
      biLoggerModel,
      editorParams,
      options,
    }),
  };
};

export type EditorLoggers = ReturnType<typeof createEditorLoggers>;
