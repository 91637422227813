import type { EditorParams } from '@/editorParams';

export function initResourceTimingBuffer(editorParams: EditorParams) {
  const performance = window.performance;

  if (!performance?.setResourceTimingBufferSize) {
    return;
  }

  if ('onresourcetimingbufferfull' in performance) {
    performance.onresourcetimingbufferfull = (() => {
      let maxSize = 150;

      return () => {
        maxSize *= 2;
        performance.setResourceTimingBufferSize(maxSize);
      };
    })();
  } else {
    performance.setResourceTimingBufferSize(editorParams.isDebug ? 1000 : 300);
  }
}
