/**
 * Workaround for the webworker cors issue:
 * https://github.com/webpack/webpack/discussions/14648#discussioncomment-1589272
 */
export class CorsWorker {
  public readonly worker: Worker;

  constructor(url: string | URL) {
    const objectURL = URL.createObjectURL(
      new Blob([`importScripts(${JSON.stringify(url.toString())});`], {
        type: 'application/javascript',
      }),
    );

    this.worker = new Worker(objectURL);

    URL.revokeObjectURL(objectURL);
  }
}
