import type { EditorModel, ServiceTopology } from 'types/core';
import { stConfig } from './stylableModuleConfig';
import type { CssMapping, Versions } from './types';
import { scriptLocation, unpkgOrNode, unpkgOrNodeObj } from './utils';

const { isOpen } = require('@wix/santa-main-r/lib/lib/common/experiment');
const joinURL = require('@wix/santa-main-r/lib/lib/common/joinURL');

export function getCSSPaths({
  editorModel,
  stylablePanelTheme,
}: {
  editorModel: EditorModel;
  stylablePanelTheme: string;
}): CssMapping {
  const themeName = stylablePanelTheme || stConfig.themeName;
  return {
    'stylable-panel': [
      `${stConfig.modulePath}/stylable-editor-themes@${
        isOpen(stConfig.experimentName, { editorModel })
          ? stConfig.themeVersion
          : '1.1.19'
      }/dist/${themeName}/${themeName}.concat.css`,
    ],
  };
}

export function getJSPaths({
  versions,
  // local,
  serviceTopology,
  editorModel,
}: {
  versions: Versions;
  local: boolean;
  serviceTopology?: ServiceTopology;
  editorModel: EditorModel;
}) {
  const wixUiSantaStaticOverrideExp = 'allowWixUiSantaStaticOverride';
  const langsLocation = scriptLocation(
    serviceTopology,
    'santa-langs',
    'http://static.parastorage.com/services/santa-langs/1.194.0',
  );
  const languageCode = editorModel?.languageCode || 'en';
  let langsUrl = joinURL(
    langsLocation,
    `resources/santa-editor/bundles/_generated/santa_editor_${languageCode}.json`,
  );
  if (langsUrl.indexOf('http:') === 0) {
    langsUrl = [langsUrl, langsUrl.replace(/http/, 'https')];
  }

  const tpaGfppDataUrl = scriptLocation(
    serviceTopology,
    'tpa-gfpp-data',
    'http://static.parastorage.com/services/tpa-gfpp-data/1.5.0',
  );
  const platformAppConfigurationUrl = scriptLocation(
    serviceTopology,
    'js-platform-apps-configuration',
    'https://static.parastorage.com/services/js-platform-apps-configuration/1.7.0',
  );

  const mmgrBaseUrl = serviceTopology
    ? serviceTopology.mediaManagerFrameUrl.replace(/\.js$/, '')
    : 'https://static.parastorage.com/unpkg-semver/media-frame@^1/MediaFrame.bundle.min';
  const scriptsDomainUrl =
    serviceTopology?.scriptsDomainUrl || 'http://static.parastorage.com/';
  const editorSdkLocation = scriptLocation(
    serviceTopology,
    'js-platform-editor-sdk',
    'https://static.parastorage.com/services/js-platform-editor-sdk/1.949.0',
  );

  const _unpkg = unpkgOrNode.bind(null, versions, false /* was local */);
  const _unpkgObj = unpkgOrNodeObj.bind(null, versions, false /* was local */);

  const artifacts = {
    marketplace: joinURL(
      scriptLocation(serviceTopology, 'marketplace-editor-container'),
      'add-panel-marketplace.bundle.min',
    ),
    marketplaceStaticsUrl: scriptLocation(
      serviceTopology,
      'marketplace-component',
    ),
    manageAppsStaticsUrl: scriptLocation(
      serviceTopology,
      'manage-installed-apps',
    ),
    ckeditor: joinURL(
      scriptsDomainUrl,
      'services',
      'ck-editor',
      '1.223.0/ckeditor',
    ),
    langs: langsUrl,
    mediaFrame: mmgrBaseUrl,
    facebook: [
      '//connect.facebook.net/en_US/all',
      _unpkg('@wix/santa-external-modules', 'facebook/facebook.min'),
    ],
    codeEditor: joinURL(
      scriptLocation(serviceTopology, 'wix-code-code-editor'),
      'static/js',
    ),
    '@wix/wix-code-classic-editor': joinURL(
      scriptLocation(serviceTopology, 'wix-code-classic-editor'),
      'index.bundle.min',
    ),
    tpaGfppData: joinURL(tpaGfppDataUrl, 'data/tpa-gfpp-data.min'),
    'js-platform-apps-configuration-editor': joinURL(
      platformAppConfigurationUrl,
      'platform-apps-editor.min',
    ),
    'js-platform-add-panel': joinURL(
      platformAppConfigurationUrl,
      'platform-add-panel.min',
    ),

    platformEvents: {
      min: joinURL(editorSdkLocation, 'lib/platformEvents.min'),
      source: joinURL(editorSdkLocation, 'lib/platformEvents'),
    },

    '@wix/platform-editor-sdk/lib/editorSDK': {
      min: joinURL(editorSdkLocation, 'lib/editorSDK.min'),
      source: joinURL(editorSdkLocation, 'lib/editorSDK'),
    },

    '@wix/wix-ui-santa': isOpen(wixUiSantaStaticOverrideExp, { editorModel })
      ? scriptLocation(serviceTopology, 'wix-ui-santa')
      : _unpkg('@wix/wix-ui-santa', 'dist/statics'),
    '@wix/adi-preset-api': _unpkg(
      '@wix/adi-preset-api',
      'dist/statics/adi-preset-api.bundle.min',
    ),
    'santa-components': _unpkgObj(
      '@wix/santa-components',
      'dist/santa-components-infra.prod',
      'dist/santa-components-infra.devel',
    ),
    skinUtils: _unpkg('@wix/santa-skin-utils', 'dist/skin-utils'),
    color: _unpkg(
      '@wix/santa-external-modules',
      'color-convert/0.2.0/color.min',
    ),
    UserFeedbackOpener: _unpkgObj(
      '@wix/user-feedback-opener',
      'dist/statics/UserFeedbackOpener.bundle.min',
      'dist/statics/UserFeedbackOpener.bundle',
    ),
    wixReactDealerViewer: joinURL(
      scriptLocation(serviceTopology, 'react-dealer-viewer'),
      'dealer-react-viewer.bundle.min',
    ),
    advancedSeoClient: joinURL(
      scriptLocation(serviceTopology, 'advanced-seo-client'),
      'app.bundle.min',
    ),
    wixDealerClientApi: joinURL(
      scriptLocation(serviceTopology, 'dealer-client-api'),
      'dealer-api.bundle.min',
    ),

    'editor-platform-host-integration': joinURL(
      scriptLocation(serviceTopology, 'editor-platform-host-integration'),
      'editor-platform-host-integration-repluggable.umd.min',
    ),

    '@wix/editor-platform-host-integration/ui': joinURL(
      scriptLocation(serviceTopology, 'editor-platform-host-integration'),
      'editor-platform-host-integration-ui.umd.min',
    ),

    TweenMax: _unpkgObj(
      'gsap',
      'src/minified/TweenMax.min',
      'src/uncompressed/TweenMax',
    ), //bundle
    TweenLite: _unpkgObj(
      'gsap',
      'src/minified/TweenMax.min',
      'src/uncompressed/TweenMax',
    ),
    TimelineMax: _unpkgObj(
      'gsap',
      'src/minified/TweenMax.min',
      'src/uncompressed/TweenMax',
    ),
    tweenEngine: _unpkg('@wix/santa-core-utils', 'dist/tweenEngine'),

    'stylable-panel': isOpen(stConfig.experimentName, { editorModel })
      ? `${stConfig.modulePath}/stylable-panel@${stConfig.experimentVersion}/dist/stylable-panel`
      : _unpkg('@wixc3/stylable-panel', 'dist/stylable-panel'),
  };

  const mbUsed = {
    speakingurl: _unpkg(
      '@wix/santa-external-modules',
      'speakingurl/7.0.0/speakingurl.min',
    ),

    aviary: _unpkg('@wix/santa-external-modules', 'aviary/3.1.0.243/feather'),

    wixFullstoryLoader: _unpkg(
      'wix-fullstory-loader',
      'dist/statics/app.bundle',
    ),
    vs: _unpkg('monaco-editor-core', 'dev/vs'),
  };

  const testing = {
    qaAutomation: _unpkg('@wix/wix-santa', 'dist/testkit'),
    'stylable-panel-drivers-test-kit': _unpkg(
      '@wixc3/stylable-panel-drivers-test-kit',
      'dist/stylable-panel-drivers-test-kit',
    ),
  };

  return {
    ...artifacts,
    ...mbUsed,
    ...testing,
  };
}
