export const TEMPLATES_BLACK_LIST = new Set([
  '4a8533be-36f0-4156-8920-79cc5d9c4948', // not migrated
  'e8b999d6-201d-4064-9511-a1c0520a50ac', // not migrated
  '06e8e719-3f93-4fba-b143-e7d050805a53', // not migrated
  '75d72669-e5ec-4640-a80d-9bd117192980', // not migrated
  '9761297c-d4d4-4006-bfb8-47bbb67ace4b', // not migrated
  '39db78f5-b1bd-495b-986c-ef23641b827f', // hidden elements
  '0049730f-c5db-4159-9985-6f359b1de1af', // hidden elements
  'c300299e-19d6-4871-a13c-f0b17f64a664', // hidden elements
  '80ed8239-04cd-46b5-9469-363cd2aa35aa', // hidden elements
  '4d224850-7694-475c-9671-f5a175d14e09', // hidden elements
  '9fecf161-bf8e-47f9-a6b8-8eea7689c3bd', // hidden elements
  '1ed50d96-47f9-4f2e-96cb-583fcfcf8e5a', // hidden elements
  '907ace81-a1a3-404a-a7ed-6f957a622843', // hidden elements
  'd643b2fc-b4ac-4f04-80f2-0d8facc74870', // hidden elements
  '1769c192-8d0a-4906-9b6c-78d5da885cc3', // hidden elements
  '4083832c-28a0-49cb-8e6b-8395c448e2c3', // hidden elements
  '9377696b-4b11-43d4-95db-4ce3bc824174', // hidden elements
  'b8133cf9-8f41-436e-b65e-89aa8d2ec326', // hidden elements
  '4641b51a-a8a5-47cc-88e3-a8968c87f8c5', // hidden elements
  'd50dbad6-8a9d-4a8d-a8a8-043aa9e95e2c', // hidden elements
  'edd84a70-c180-4f4b-8f78-2827763e5737', // hidden elements
  '8cc2b437-b3ff-4e24-9fb6-bd8b51c1018f', // hidden elements
  '9c31ea68-1d79-47d5-a8ea-382388573e2e', // hidden elements
  '7dd080af-191f-45b9-a35b-839a102acb36', // hidden elements
  'e3603e67-3f70-4f98-a7f3-b8654bee7cff', // hidden elements
  'e3e13f7a-7366-459c-9a97-08c6ee96d8f1', // hidden elements
  '75715458-a745-4be7-8f93-0eac6751042d', // hidden elements
  'b0cca914-8e1e-4bd7-96ef-f6a2fc9867fc', // hidden elements
  '0253521a-e172-45bc-a52f-50fbbd1601c7', // hidden elements
  'c910370d-ddd6-4f2b-b3e6-1fbc5fdfb899', // hidden elements
  '39e61064-3323-4068-b899-8af63219c867', // hidden elements
  'c705a048-911d-4a6f-b614-751a992d8945', // hidden elements
  'f9121607-d060-431e-a708-5ae610ee5f9a', // overlapping
  '44361882-aac5-4cb2-8393-a1d0bea73a57', // overlapping
  'e5696fa8-667f-47df-9f2f-f18bba833e78', // overlapping
]);
