import type { Experiment } from 'experiment';
import type { EditorLoggers } from '../editorLoggers';
import type { BaseHooks } from '../types';

import { editorBrowserWindowFocus } from '@wix/bi-logger-editor/v2';

export function registerToTabSwitchEvent({
  experiment,
  editorLoggers,
  hooks,
}: {
  experiment: Experiment;
  editorLoggers: EditorLoggers;
  hooks: BaseHooks;
}) {
  window.addEventListener('visibilitychange', function () {
    const isCurrentTabActive = !document.hidden;

    hooks.tabChanged.fire({ isCurrentTabActive });

    if (experiment.isOpen('se_moveBiLoggerToEditor')) {
      editorLoggers.bi.logger.report(
        editorBrowserWindowFocus({
          actionName: isCurrentTabActive ? 'suspend' : 'activate',
        }),
      );
      return;
    }

    window.bi.event(1140, {
      prefetch: window.afterEditorPrefetch,
      esi: window.editorModel.editorSessionId,
      msid: window.editorModel.metaSiteId,
      uuid: window.editorModel.permissionsInfo.ownerId,
      actionName: isCurrentTabActive ? 'suspend' : 'activate',
    });
  });
}
