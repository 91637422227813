import type { BiType } from './biLogger';
import { createBi } from './biLogger';
import type { EditorModel } from 'types/core';
import type { EditorParams } from '@/editorParams';
import type { RequireJS } from 'requirejs';
import { initResourceTimingBuffer } from './instrument/resourceTimingBuffer';
import type { Experiment } from 'experiment';
import type { EditorLoggers } from './editorLoggers';

function registerErrorHandlers({
  requirejs,
  window,
  bi,
}: {
  requirejs: any;
  window: any;
  bi: BiType;
}) {
  let sendErrorOnce: any = function (
    errorName: AnyFixMe,
    errorCode: AnyFixMe,
    severity: AnyFixMe,
  ) {
    //eslint-disable-line func-style
    sendErrorOnce = function () {}; // only report one error per session
    const params = Array.prototype.slice
      .call(arguments, 3)
      .reduce((result, arg, index) => {
        result['p' + (index + 1)] = arg; //eslint-disable-line prefer-template
        return result;
      }, {});
    bi.error(
      {
        errorName,
        errorCode,
        severity,
      },
      params,
    );
  };

  const { onerror } = window;
  window.onerror = function (
    errorMsg: AnyFixMe,
    url: AnyFixMe,
    line: AnyFixMe,
    column: AnyFixMe,
    err: AnyFixMe,
  ) {
    const where = err?.stack ? `${err.stack}` : url;
    const { href } = window.location;
    sendErrorOnce('JAVASCRIPT_ERROR', 111022, 'fatal', errorMsg, where, href); // JAVASCRIPT_ERROR from packages/core/bi/errors.js
    if (onerror) {
      return onerror.apply(this, arguments);
    }
  };

  if (window.console) {
    const { error } = console;
    if (error) {
      console.error = function (...args) {
        const { href } = window.location;
        sendErrorOnce('CONSOLE_ERROR', 111023, 'error', href, ...args); // CONSOLE_ERROR from packages/core/bi/errors.js
        return error.apply(this, args);
      };
    }
  }

  requirejs.onError = function (err: AnyFixMe) {
    // because ajv requires js-beautify synchronously even if does not use
    if (
      err.message?.indexOf &&
      err.message.indexOf(
        'Module name "js-beautify" has not been loaded yet',
      ) === 0
    ) {
      return;
    }
    const {
      requireModules = [],
      message,
      stack,
      errn = 'REQUIREJS_ERROR',
      errc = 111024,
      severity = 'fatal',
      requireType,
    } = err;
    const modules = requireModules.join(';');
    const what = stack ? '' + stack : message; //eslint-disable-line prefer-template
    const { href } = window.location;
    sendErrorOnce(errn, errc, severity, what, modules, href, requireType); // REQUIREJS_ERROR from packages/core/bi/errors.js
  };
}

export default function instrument({
  window,
  requirejs,
  experiment,
  queryUtil,
  editorLoggers,
  editorModel,
  editorParams,
}: {
  window: any;
  requirejs: RequireJS;
  experiment: Experiment;
  editorLoggers: EditorLoggers;
  queryUtil: any;
  editorModel: EditorModel;
  editorParams: EditorParams;
}) {
  editorModel = editorModel || ({} as EditorModel);

  const { editorVersion } = editorModel;

  window.performance?.mark('main-r loaded');

  const bi = experiment.isOpen('se_moveBiLoggerToEditor')
    ? (editorLoggers.bi.loggerLegacy as BiType)
    : createBi({
        window,
        editorModel,
        suppressbi: !editorParams.isBiErrorsAndFedopsEnabled,
      });

  initResourceTimingBuffer(editorParams);

  if (!window.bi) {
    // https://github.com/wix-private/santa-editor/search?q=window.bi
    window.bi = bi;
  }

  const forceBi = queryUtil.isParameterTrue('bi');

  const shouldRegisterErrors =
    editorVersion &&
    !editorParams.isDebug &&
    editorParams.isBiErrorsAndFedopsEnabled;

  if (forceBi || shouldRegisterErrors) {
    registerErrorHandlers({ window, requirejs, bi });
  }
}
