import type { EditorModel } from 'types/core';
import type { FedopsLogger } from 'types/fedops';
import type { StartupConfig } from '../common';
import { EditorBootstrapError, EditorBootstrapErrorCode } from '../errors';
import { loadPolyfills } from './loadPolyfills';

export async function loadMainPackages(
  {
    fedopsLogger,
    editorModel,
  }: {
    fedopsLogger: FedopsLogger;
    editorModel: EditorModel;
  },
  config: StartupConfig,
) {
  fedopsLogger.appLoadingPhaseStart('main-chunk-load-main-packages');

  if (config.shouldLoadPolyfills) {
    await loadPolyfills();
  }

  fedopsLogger.appLoadingPhaseStart('main-chunk-load-rEditor');
  const [, rEditor] = await Promise.all([
    config.loadMainCss
      ? Promise.all([
          //eslint-disable-next-line @wix/santa-editor/scoped-imports
          import('@/rEditor/editor.global.scss'),
          /**
           * Fonts that we use are compressed and some symbols are missing.
           * Because of this, Vietnamese looks bad. Different symbols are rendered with different fonts.
           * We need to use other fonts. This is expected to be fixed by uploading full versions of fonts,
           * but meanwhile we need to add fallback fonts for Vietnamese locale
           * TODO: remove editor-vi-fonts.global.scss after main fonts are updated to include all needed symbols
           */
          editorModel.languageCode === 'vi'
            ? //eslint-disable-next-line @wix/santa-editor/scoped-imports
              import('@/rEditor/editor-vi-fonts.global.scss')
            : undefined,
        ])
      : undefined, // should be loaded first because of css ordering
    import('@/rEditor'),
  ]).catch((error) => {
    console.error(error);
    throw new EditorBootstrapError('Failed to load @/rEditor', {
      code: EditorBootstrapErrorCode.LoadPackagesError,
      originalError: error,
    });
  });
  fedopsLogger.appLoadingPhaseFinish('main-chunk-load-rEditor');

  fedopsLogger.appLoadingPhaseFinish('main-chunk-load-main-packages');

  return {
    rEditor,
  };
}
