window.define('json', function () {
    const CACHE_BUST_QUERY_PARAM = 'bust';
    const CACHE_BUST_FLAG = '!bust';

    const buildMap = {};

    function cacheBust(url) {
        url = url.replace(CACHE_BUST_FLAG, '');
        url += url.includes('?') ? '&' : '?';
        return `${url + CACHE_BUST_QUERY_PARAM}=${Math.round(2147483647 * Math.random())}`;
    }

    function download(urls, isFetchingFallback) {
        const url = urls[0];
        return fetch(url, {credentials: 'same-origin'})
            .then(function (response) {
                if (response.ok) {
                    return response.json()
                        .then(function(json){
                            if (isFetchingFallback) {
                                json.isUsingFallback = true;
                            }
                            return json;
                        });
                }
                return Promise.reject(response);
            })
            .catch(function (err) {
                if (urls.length > 1) {
                    return download(urls.slice(1));
                } else if (!isFetchingFallback){
                    return download(['https://static.parastorage.com/services/santa-langs/1.7380.0/resources/santa-editor/bundles/_generated/santa_editor_en.json'], true);
                } else if (err.text){
                    return err.text().then(function(e) { return Promise.reject(`url: ${url}, fetch text: ${e}`);});
                }
                throw err;
            });
    }

    //API
    return {
        load(name, req, onLoad, config) {
            if ((config.isBuild && (config.inlineJSON === false || name.includes(`${CACHE_BUST_QUERY_PARAM}=`))) ||
                (req.toUrl(name).startsWith('empty:'))) {
                //avoid inlining cache busted JSON or if inlineJSON:false
                //and don't inline files marked as empty!
                onLoad(null);
            } else {
                let urls = config.paths[name];
                if (!urls) {
                    urls = [req.toUrl(name)];
                } else if (!Array.isArray(urls)) {
                    urls = [urls];
                }
                urls = urls.map(req.toUrl.bind(req));

                download(urls)
                    .then(function(json) {
                        if (config.isBuild) {
                            buildMap[name] = json;
                        }
                        onLoad(json);
                    }).catch(function(err){
                        const error = new Error(err || 'json!langs failed to load');
                        error.requireModules = ['json!langs'];
                        onLoad.error(error);
                    });
            }
        },

        normalize (name, normalize) {
            // used normalize to avoid caching references to a "cache busted" request
            if (name.includes(CACHE_BUST_FLAG)) {
                name = cacheBust(name);
            }
            // resolve any relative paths
            return normalize(name);
        },

        //write method based on RequireJS official text plugin by James Burke
        //https://github.com/jrburke/requirejs/blob/master/text.js
        write(pluginName, moduleName, write) {
            if (moduleName in buildMap) {
                const content = JSON.stringify(buildMap[moduleName]);
                write(`define("${pluginName}!${moduleName}", function() { return ${content}; });\n`);
            }
        }
    };
});
