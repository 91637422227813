export const POC_TEMPLATE_IDS = new Set([
  'd573cb3a-158b-4280-a9e2-4a39dcab07a1',
  'db0e4d28-00b2-4bcb-b155-a296bf37be40',
  'a6ae81a4-3e3e-41b3-9931-2bb7ab7b5ee5',
  '0d60ca1a-c977-4692-80a8-6598da585d74',
  'b639f344-d2b5-46b3-9787-65117d3b2292',
  'd6e60452-0704-43c0-97c0-2a5e14333e61',
  '7a8481f6-3ea9-405e-a645-15be7d6c34d3',
  '219370bc-8a83-47f6-bd68-bc2f81415293',
  'a807a359-47ff-4c2d-8190-81a72510a92c',
  'bdc40968-38d9-42d6-b5fa-4f481e8e3325',
]);

// duplicated from '@wix/santa-editor-utils/siteCreation'
export const SITE_CREATION_TEMPLATE_ID = '6e51b1b9-0fa7-42df-9637-771a4543f4f4';
