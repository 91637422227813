import type { Experiment } from 'experiment';
import type { EditorModel } from 'types/core';
import type { MigrationFlow } from '@/sectionsMigration';

import { TEMPLATES_BLACK_LIST } from './templatesBlackList';

const isTemplateMigrationFlow = ({
  experiment,
  editorModel,
}: {
  experiment: Experiment;
  editorModel: EditorModel;
}): boolean => {
  const isExperimentEnabled = experiment.isOpen('se_sectionsMigration');
  const isNewSite = editorModel.isDraft || editorModel.firstSave;
  const isBlackListed =
    !experiment.isOpen('se_sectionsMigrationDisableBlackList') &&
    TEMPLATES_BLACK_LIST.has(editorModel.siteHeader.originalTemplateId);

  // home page labelling condition includes to conditions after loading phase
  return (
    (isExperimentEnabled && isNewSite && !isBlackListed) ||
    experiment.isOpen('se_sectionsForceMigration')
  );
};

const isADIMigrationFlow = ({
  experiment,
  editorModel,
  queryUtil,
}: {
  experiment: Experiment;
  editorModel: EditorModel;
  queryUtil: any;
}): boolean =>
  experiment.isOpen('se_sectionsMigrationADI') &&
  editorModel.onboardingEditorUrl && // assume it is equal to editorAPI.generalInfo.isSiteFromOnBoarding()
  queryUtil.isParameterTrue('sectionsMigration');

const isEditorMigrationFlow = ({
  experiment,
}: {
  experiment: Experiment;
}): boolean => experiment.isOpen('se_sectionsMigrationEditor');

export function getSectionsMigrationFlow({
  isInsideEditorX,
  isInsideAppStudio,
  experiment,
  editorModel,
  queryUtil,
}: {
  isInsideEditorX: boolean;
  isInsideAppStudio: boolean;
  experiment: Experiment;
  editorModel: EditorModel;
  queryUtil: any;
}): MigrationFlow {
  if (
    isInsideAppStudio ||
    isInsideEditorX ||
    experiment.isOpen('se_kill_sections')
  ) {
    return;
  }

  let migrationFlow: MigrationFlow;

  switch (true) {
    case isTemplateMigrationFlow({ experiment, editorModel }):
      migrationFlow = <MigrationFlow>'template';
      break;
    case isADIMigrationFlow({ experiment, editorModel, queryUtil }):
      migrationFlow = <MigrationFlow>'adi';
      break;
    case isEditorMigrationFlow({ experiment }):
      migrationFlow = <MigrationFlow>'editor';
      break;
  }

  return migrationFlow;
}
