import { ErrorReporter } from '@wix/editor-error-reporter';
// NOTE: ensure only type imported. The size of initial loader should be minimal
import type { FedopsLogger } from 'types/fedops';
import type {
  InitialLoaderMode,
  InitInitialLoaderResult,
} from 'types/initialLoader';
import type { LoaderTimelineMode } from './progressiveInitialLoader/types';

import type { EditorParams } from '@/editorParams';
import type { Experiment } from 'experiment';

export function getInitialLoaderMode({
  editorParams,
  experiment,
}: {
  editorParams: EditorParams;
  experiment: Experiment;
}): InitialLoaderMode {
  const canShowProgressiveLoader =
    !editorParams.isInsideEditorX &&
    !editorParams.isInsideAppStudio &&
    !editorParams.siteCreationWizard;

  if (!canShowProgressiveLoader) {
    return 'base';
  }

  const isEditor2Experience =
    editorParams.isSectionsExperienceEnabled ||
    Boolean(editorParams.sectionsMigrationFlow);

  if (
    experiment.isOpen('se_progressiveLoader') ||
    (experiment.isOpen('se_progressiveLoaderInEditor2') && isEditor2Experience)
  ) {
    return 'progressive';
  }

  return 'base';
}

// NOTE: base initial loader is rendered from the server side
const getLoaderContainer = (): HTMLDivElement =>
  window.document.getElementById('video-preloader') as HTMLDivElement;

export function initBaseInitialLoader(): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();

  return {
    unmount: () => {
      loaderContainer?.parentNode?.removeChild(loaderContainer);
    },
  };
}

const reportError = (error: Error): void => {
  ErrorReporter.captureException(error, {
    tags: {
      operation: 'progressive-initial-loader',
    },
  });
};

export function initProgressiveInitialLoader({
  fedopsLogger,
  timelineMode,
}: {
  fedopsLogger: FedopsLogger;
  timelineMode: LoaderTimelineMode;
}): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();

  fedopsLogger.appLoadingPhaseStart('main-chunk-init-initial-loader');

  const mountComponentPromise = import('./progressiveInitialLoader').then(
    async ({ mountLoader }) =>
      mountLoader({
        timelineMode,
        loaderContainer,
        reportError,
      }),
  );

  mountComponentPromise.then(() => {
    fedopsLogger.appLoadingPhaseFinish('main-chunk-init-initial-loader');
  });

  return {
    unmount() {
      mountComponentPromise
        .then(
          ({ unmount }) => {
            unmount();
          },
          (error) => {
            reportError(error);
          },
        )
        .finally(() => {
          loaderContainer?.parentNode?.removeChild(loaderContainer);
        });
    },
  };
}
