import { CorsWorker as Worker } from './CorsWorker';

import { WorkerEventAction, WorkerEventData } from './types';
import type { AliveReportConfig } from './types';

const HEALTH_CHECK_MESSAGE_INTERVAL = 1000;

export function initMainThreadHealthCheck(reportConfig: AliveReportConfig) {
  // `corsWorker` - i know, it should be easier[1], but with default approach, there is a CORS issue:
  // https://github.com/webpack/webpack/discussions/14648#discussioncomment-1589272
  // [1]: https://webpack.js.org/guides/web-workers/
  const { worker } = new Worker(new URL('./worker/worker.ts', import.meta.url));

  function postMessage(data: WorkerEventData) {
    worker.postMessage(data);
  }

  function postInitReporting() {
    postMessage({
      action: WorkerEventAction.Init,
      initConfig: { reportConfig },
    });
  }

  function postIsAlive() {
    postMessage({ action: WorkerEventAction.Alive });
  }

  function postPause() {
    postMessage({ action: WorkerEventAction.Pause });
  }

  function postResume() {
    postMessage({ action: WorkerEventAction.Resume });
  }

  postInitReporting();
  postIsAlive();

  const intervalHandle = setInterval(
    postIsAlive,
    HEALTH_CHECK_MESSAGE_INTERVAL,
  );

  return {
    pause: postPause,
    resume: postResume,
    destroy() {
      clearInterval(intervalHandle);

      worker.terminate();
    },
  };
}

export type MainThreadHealthChecker = ReturnType<
  typeof initMainThreadHealthCheck
>;
