import getFullRjsConfig from '@wix/santa-main-r/lib/lib/common/getFullRjsConfig';
import requirejs from 'requirejs';
import PackagesUtil from '@wix/santa-main-r/lib/lib/common/PackagesUtil';
import { getRjsConfig } from './get-rjs-config';
import { getJSPaths } from './get-rjs-paths';
import type { EditorModel } from 'types/core';
import type { Versions } from './types';

const packagesUtil = new PackagesUtil([], window.location.search);

export function setupRequire({
  local,
  editorModel,
  versions,
}: {
  local: boolean;
  editorModel: EditorModel;
  versions: Versions;
}) {
  const paths = getJSPaths({
    versions,
    local,
    serviceTopology: window.serviceTopology,
    editorModel,
  });

  const config = getFullRjsConfig(
    'santa-editor',
    window.editorBase,
    getRjsConfig(window.editorBase, paths),
    packagesUtil,
    window.serviceTopology,
  );

  requirejs.config(config);
}
