import { factory } from '@wix/web-bi-logger';

import { EDITOR_BI_ENDPOINT, EDITOR_BI_SOURCE } from './constants';
import { createLegacyBiLogger } from './biLoggerLegacy';
import { getBILoggerInitialParams } from './getBILoggerInitialParams';

import type { EditorModel } from 'types/core';
import type { EditorParams } from '@/editorParams';
import type { LoggerOptions } from '../types';

export const createBiLogger = ({
  editorModel,
  editorParams,
  options: { useBatch },
}: {
  editorModel: EditorModel;
  editorParams: EditorParams;
  options: LoggerOptions;
}) => {
  const initialDefaults = getBILoggerInitialParams({
    editorModel,
    editorParams,
  });

  const biLoggerFactory = factory({
    endpoint: EDITOR_BI_ENDPOINT,
    useBatch,
  })
    .setMuted(!editorParams.isBiErrorsAndFedopsEnabled)
    .updateDefaults({
      src: EDITOR_BI_SOURCE,
      ...initialDefaults,
    });

  const biLogger = biLoggerFactory.logger();

  return {
    logger: biLogger,
    loggerLegacy: createLegacyBiLogger(biLogger),
    /**
     * Exported to be used in biActions.
     * TODO: remove `_initialDefaults` after `se_moveBiLoggerToEditor` merged
     * @private
     */
    _initialDefaults: initialDefaults,
    updateDefaults: (params: Record<string, any>) => {
      biLoggerFactory.updateDefaults(params);
    },
  };
};
