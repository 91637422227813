const getIntegrationPath =
  require('@wix/santa-main-r/lib/lib/common/getIntegrationPath').default;

function getRjsConfig(editorBase: AnyFixMe, paths: AnyFixMe) {
  return {
    baseUrl: '',
    paths,
    packages: ['platformIntegrationEditor', 'tpaIntegrationEditor'].map(
      (packageName) => ({
        name: packageName,
        main: packageName,
        location: getIntegrationPath(packageName, editorBase),
      }),
    ),
    shim: {
      color: { exports: 'Color' },
      lodash: { exports: '_' },
      keyboardMaster: { exports: 'key' },
      mediaFrame: { exports: 'MediaFrame', deps: ['zepto'] },
      aviary: { exports: 'featherEditor' },
      facebook: { exports: 'FB' },
      'jasmine-html': { deps: ['jasmine'] },
      'jasmine-boot': { deps: ['jasmine', 'jasmine-html'] },
      qaAutomation: { exports: 'initSanta' },
      ReactProxy: {
        deps: ['react'],
        exports: 'ReactProxy',
      },
    },
    map: {
      '*': {
        coreUtilsLib: '@wix/santa-core-utils',
        //         imageClientApi: 'image-client-api',
        baseUILib: '@wix/wix-base-ui',
        reactDOM: 'react-dom',
      },
    },
    jsx: {
      baseDefines: { lodash: '_', react: 'React' },
      fileExtension: '.html',
    },
    waitSeconds: 0,
  };
}

export { getRjsConfig };
