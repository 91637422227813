const memoByArg = <T>(cb: (arg: string) => T): ((arg: string) => T) => {
  const cache = new Map();
  return (arg) => {
    if (cache.has(arg)) {
      return cache.get(arg);
    }
    const res = cb(arg);
    cache.set(arg, res);
    return res;
  };
};

export const loadCss = memoByArg((href: string) => {
  return new Promise<void>((resolve) => {
    const head = document.getElementsByTagName('head')[0];
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.href = href;
    if ('onload' in linkTag) {
      linkTag.onload = () => {
        resolve();
      };
    } else {
      setTimeout(() => {
        resolve();
      }, 200);
    }

    head.appendChild(linkTag);
  });
});
