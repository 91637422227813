import { editorBrowserWindowClose } from '@wix/bi-logger-editor/v2';

import type { Versions } from '../types';
import type { ServiceTopology } from 'types/core';
import type { Experiment } from 'experiment';
import type { EditorLoggers } from '../editorLoggers';

function unpkg(pkg: string, v: string, p: string) {
  return 'https://static.parastorage.com/unpkg/'
    .concat(pkg, '@')
    .concat(v, '/')
    .concat(p); // return `//unpkg.parastorage.com/${pkg}@${v}/${p}`
}

function unpkgObj(pkg: string, v: string, min: string, debug: string) {
  return {
    min: unpkg(pkg, v, min),
    source: unpkg(pkg, v, debug),
  };
}

function node(m: string, p: string) {
  return 'node_modules/'.concat(m, '/').concat(p);
}

function nodeObj(m: string, min: string, debug: string) {
  return {
    min: node(m, min),
    source: node(m, debug),
  };
}

export function scriptLocation(
  serviceTopology: ServiceTopology,
  name: string,
  fallback?: string,
) {
  return (
    (serviceTopology && serviceTopology.scriptsLocationMap[name]) || fallback
  );
}

export function unpkgOrNode(
  versions: Versions,
  local: boolean,
  m: string,
  p: string,
) {
  const actualLocal = isLocal(versions, local, m);
  return actualLocal ? node(m, p) : unpkg(m, versions[m], p);
}

export function isLocal(versions: Versions, local: Boolean, m: string) {
  return local || versions[m] === 'link';
}

export function unpkgOrNodeObj(
  versions: Versions,
  local: boolean,
  m: string,
  min: string,
  debug: string,
) {
  const actualLocal = isLocal(versions, local, m);
  return actualLocal
    ? nodeObj(m, min, debug)
    : unpkgObj(m, versions[m], min, debug);
}

export const VERSION_REGEXP = /\/(\d+\.\d+\.\d+)\/?$/;

export function getBaseVersion(url: string) {
  const semverMatches = url.match(VERSION_REGEXP);
  return semverMatches && semverMatches[1];
}

export function registerToTabLeaveEvent({
  experiment,
  editorLoggers,
}: {
  experiment: Experiment;
  editorLoggers: EditorLoggers;
}) {
  window.onunload = function () {
    if (experiment.isOpen('se_moveBiLoggerToEditor')) {
      editorLoggers.bi.logger.report(editorBrowserWindowClose({}));
    } else {
      window.bi.event(1138, {
        esi: window.editorModel.editorSessionId,
        msid: window.editorModel.metaSiteId,
        uuid: window.editorModel.permissionsInfo.ownerId,
      });
    }

    // NOTE: in case if batching enabled, flush the batch
    window.bi.flush?.();
    editorLoggers.bi.logger.flush();
    editorLoggers.fedops.logger.flush();
  };
}
