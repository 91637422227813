import { initMainThreadHealthCheck } from './healthCheck';
import { editorStuck } from '@wix/bi-logger-editor/v2';
import { pickByValuePredicate } from './utils';
import type { BaseHooks } from './types';
import type { ValuesOfReturnFnValues } from 'types/core';
import type { BiLoggerInitialParams } from 'types/bi';

const ALIVE_TIMEOUTS = [10 /*sec*/ * 1000, 30 * 1000, 60 * 1000];

export function initHealthCheck({
  hooks,
  biDefaults,
}: {
  hooks: BaseHooks;
  biDefaults: ValuesOfReturnFnValues<BiLoggerInitialParams>;
}) {
  // filter out unresolved default bi params
  const biDefaultsPlain: Partial<BiLoggerInitialParams> = pickByValuePredicate(
    biDefaults,
    (value) => typeof value !== 'function',
  );

  const mainThreadHealthChecker = initMainThreadHealthCheck({
    timeouts: ALIVE_TIMEOUTS,
    logObject: editorStuck(biDefaultsPlain),
  });

  hooks.tabChanged.tap(({ isCurrentTabActive }) => {
    if (!isCurrentTabActive) {
      mainThreadHealthChecker.pause();
    } else {
      mainThreadHealthChecker.resume();
    }
  });

  hooks.siteCreationCreatePresets.tap(({ isCreatePresetsInProgress }) => {
    if (isCreatePresetsInProgress) {
      mainThreadHealthChecker.pause();
    } else {
      mainThreadHealthChecker.resume();
    }
  });
}
